var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"common-bg wrap hidden"},[_c('van-nav-bar',{attrs:{"fixed":true,"title":_vm.$t('page.certification'),"left-arrow":"","placeholder":""},on:{"click-left":_vm.onClickLeft}}),_c('div',{staticClass:"top18"},[_c('div',{staticClass:"padding0-12"},[_c('div',{staticClass:"padding24-20 radius10"},[_c('div',{staticClass:"flex between"},_vm._l((_vm.typeArr),function(item,index){return _c('div',{key:index,staticClass:"btn_wrap flex flex-center common-bg round font30 type_item",style:({
              backgroundColor: _vm.type === item.value ? 'var(--nav-background)' : '',
              color: _vm.type === item.value ? 'var(--theme)' : 'var(--sizeColor)',
            }),on:{"click":function($event){return _vm.typeChange(item)}}},[_vm._v(" "+_vm._s(item.title)+" ")])}),0),_c('div',{staticClass:"top70 font24 sizeColor"},[_vm._v(_vm._s(_vm.$t("Certification.name")))]),_c('div',{staticClass:"top16"},[_c('Input',{attrs:{"disabled":_vm.status !== 0},model:{value:(_vm.formData.realname),callback:function ($$v) {_vm.$set(_vm.formData, "realname", $$v)},expression:"formData.realname"}})],1),_c('div',{staticClass:"top36 font24 sizeColor"},[_vm._v(_vm._s(_vm.typeObj[_vm.type]))]),_c('div',{staticClass:"top16"},[_c('Input',{attrs:{"disabled":_vm.status !== 0},model:{value:(_vm.formData.cardid),callback:function ($$v) {_vm.$set(_vm.formData, "cardid", $$v)},expression:"formData.cardid"}})],1),_c('div',{staticClass:"top36 font24 sizeColor"},[_vm._v(_vm._s(_vm.$t("Certification.upload")))]),_c('div',{staticClass:"top16 flex upload"},[_c('div',[_c('van-uploader',{style:({
                width: 'calc(220rem / 32)',
                height: 'calc(220rem / 32)',
              }),attrs:{"after-read":_vm.card_frontUpload,"show-upload":_vm.card_front.length === 0,"deletable":_vm.status === 0},on:{"delete":function($event){return _vm.deleteImage('card_front')}},model:{value:(_vm.card_front),callback:function ($$v) {_vm.card_front=$$v},expression:"card_front"}})],1),_c('div',{staticClass:"left30"},[_c('van-uploader',{style:({
                width: 'calc(220rem / 32)',
                height: 'calc(220rem / 32)',
              }),attrs:{"deletable":_vm.status === 0,"after-read":_vm.card_backUpload,"show-upload":_vm.card_back.length === 0},on:{"delete":function($event){return _vm.deleteImage('card_back')}},model:{value:(_vm.card_back),callback:function ($$v) {_vm.card_back=$$v},expression:"card_back"}})],1)]),_c('div',{staticClass:"top80"},[_c('div',{staticClass:"radius10 flex flex-center font36 height80 btnSizeColor btnColor",style:({ backgroundColor: _vm.status === 2 ? '#5ac725' : _vm.status === 1 ? '#f9ae3d' : '' }),on:{"click":_vm.submit}},[_vm._v(" "+_vm._s(_vm.status == 0 ? _vm.$t("Certification.submit") : _vm.status == 1 ? _vm.$t("Certification.review") : _vm.$t("Certification.verified"))+" ")])]),_c('div',{staticClass:"top80"})])])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }